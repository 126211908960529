<template>
  <asom-button outline size="sm" @click="getOptions" text="Extend" />
  <asom-modal title="Extend Shift" v-model="showModal" :size="modalSize">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div v-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div v-else-if="data.length > 0">
      <asom-form-field
        label="Select shift to cover"
        required
        :state="inputStates('formData.selectedShift')"
        error="Shift is required"
      >
        <asom-client-table
          class="mb-2"
          :data="data"
          :columns="[
            'check',
            'officer',
            'station',
            'shift',
            'workType',
            'requirementForFullShift',
          ]"
          :filterable="false"
          :pagination="false"
        >
          <template v-slot:header_check><span></span></template>
          <template v-slot:header_workType>Work Type</template>
          <template v-slot:header_requirementForFullShift
            >Full-shift cover</template
          >
          <template v-slot:check="scopedSlots">
            <input
              type="radio"
              :value="scopedSlots.rowData"
              v-model="formData.selectedShift"
            />
          </template>
          <template v-slot:officer="slotScoped">{{
            get(slotScoped.rowData, "roster.officer")
          }}</template>
          <template v-slot:station="slotScoped">{{
            get(slotScoped.rowData, "roster.station.stationName")
          }}</template>
          <template v-slot:shift="slotScoped">{{
            get(slotScoped.rowData, "roster.dws")
          }}</template>
          <template v-slot:requirementForFullShift="slotScoped">{{
            slotScoped.data ? "Yes" : "No"
          }}</template>
          <template v-slot:workType="slotScoped">{{
            getWorkType(slotScoped.rowData)
          }}</template>
        </asom-client-table>
      </asom-form-field>
      <div class="grid sm:grid-cols-1 md:grid-cols-2">
        <asom-form-field v-if="showDateTimeInput" label="Start Time">
          <span>{{
            displayDateTime(
              get(formData, "selectedShift.coverageRequirementStart")
            )
          }}</span>
        </asom-form-field>
        <asom-form-field v-if="showDateTimeInput" label="End Time">
          <span>{{
            displayDateTime(
              get(formData, "selectedShift.coverageRequirementEnd")
            )
          }}</span>
        </asom-form-field>
        <asom-form-field
          v-if="showDateTimeInput"
          :label="dateTimeInputLabel"
          :required="showDateTimeInput"
          :state="dateTimeInputState"
          error="Date time is required"
        >
          <asom-input-date-time v-model="formData.dateTime" />
        </asom-form-field>
        <div></div>
        <asom-form-field v-if="showDateTimeInput && showForceCheckbox" label="">
          <asom-input-checkbox
            class="mt-3"
            :checked="formData.forced"
            @change="onForcedCheckboxChange"
            label="Force to proceed?"
            description="Ignore the warning for maximum monthly workable OT hours"
          />
        </asom-form-field>
      </div>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="assignOfficer"
            :disabled="isAssigning"
            :loading="isAssigning"
            :state="dateTimeInputState"
            text="Confirm"
          />
        </div>
        <div>
          <asom-button
            @click="showModal = false"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </div>
    <p v-else>No officer available</p>
  </asom-modal>
</template>
<script>
import get from "lodash.get";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import {
  assignExtendedOT,
  getExtensionVacants,
} from "../../../services/manpower.service";
import {
  toMoment,
  parseDateTime,
  displayDateTime,
} from "@/helpers/dateTimeHelpers";

export default {
  mixins: [inputStates],
  props: {
    roster: {
      type: undefined,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        selectedShift: null,
        dateTime: new Date(),
        forced: false,
      },
      showModal: false,
      showForceCheckbox: false,
      isLoading: false,
      isAssigning: false,
      data: [],
      error: null,
    };
  },
  validations() {
    return {
      formData: {
        selectedShift: { required },
      },
    };
  },
  watch: {
    showModal: function(newValue) {
      if (!newValue) {
        this.data = [];
      } else {
        const planDate = get(this.roster, "planDate");
        if (planDate) this.formData.dateTime = toMoment(planDate).toDate();
      }
    },
    "formData.selectedShift": function(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.showForceCheckbox = false;
        const {
          requirementForFullShift = false,
          onExtensionNeedForward = false,
          coverageRequirementStart,
          coverageRequirementEnd,
        } = newValue;
        const start = toMoment(coverageRequirementStart);
        const end = toMoment(coverageRequirementEnd);
        let newDateTime = null;
        if (requirementForFullShift) {
          newDateTime = toMoment(coverageRequirementStart);
          newDateTime.add(end.diff(start) / 2);
        } else if (onExtensionNeedForward) {
          newDateTime = end;
        } else {
          newDateTime = start;
        }
        this.formData.dateTime = newDateTime.toDate();
      }
    },
    "formData.dateTime": function(newValue) {
      if (newValue) {
        this.showForceCheckbox = false;
      }
    },
  },
  computed: {
    modalSize() {
      return this.isLoading || this.data.length === 0 ? "sm" : "lg";
    },
    showDateTimeInput() {
      return this.formData.selectedShift != null;
    },
    dateTimeInputLabel() {
      if (this.showDateTimeInput) {
        const extendForward = get(
          this.formData,
          "selectedShift.onExtensionNeedForward",
          false
        );
        return extendForward ? "Extended End Time" : "Extended Start Time";
      }
      return "";
    },
    dateTimeInputState() {
      return !this.v$.$invalid &&
        (!this.showDateTimeInput || this.formData.dateTime != null)
        ? "default"
        : "error";
    },
  },
  methods: {
    get,
    displayDateTime,
    onForcedCheckboxChange() {
      this.formData.forced = !this.formData.forced;
    },
    getWorkType({
      completeShift = false,
      onExtensionNeedForward = false,
      onExtensionNeedBackward = false,
    }) {
      let result = "";
      if (completeShift) result = "Complete Shift";
      else if (onExtensionNeedForward) result = "Extend Forward";
      else if (onExtensionNeedBackward) result = "Extend Backward";
      return result;
    },
    async getOptions() {
      this.showModal = true;
      this.isLoading = true;
      this.error = null;
      var resp = await getExtensionVacants(get(this.roster, "rosterId"));
      if (resp.success) {
        this.data = get(resp, "payload.list", []);
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    getAssignmentData() {
      let result = {
        stationId: get(this.roster, "station.stationId"),
        lineId: get(this.roster, "line.lineId"),
      };

      const currentOfficerId = get(this.roster, "officerId");
      const currentPlanDate = parseDateTime(get(this.roster, "planDate"));
      const selectedRosterId = get(
        this.formData,
        "selectedShift.roster.rosterId"
      );

      const extendForward = get(
        this.formData,
        "selectedShift.onExtensionNeedForward",
        false
      );
      if (extendForward) {
        result.replacingRosterId = selectedRosterId;
        result.priorOfficerId = currentOfficerId;
        result.priorOfficerPlanDate = currentPlanDate;
        const priorOfficerShiftEnd = this.formData.dateTime;
        result.priorOfficerShiftEnd = parseDateTime(priorOfficerShiftEnd);
      } else {
        result.replacingRosterId = selectedRosterId;
        result.afterOfficerId = currentOfficerId;
        result.afterOfficerPlanDate = currentPlanDate;
        const afterOfficerShiftStart = this.formData.dateTime;

        result.afterOfficerShiftStart = parseDateTime(afterOfficerShiftStart);
      }
      if (this.showForceCheckbox) {
        result.forced = this.formData.forced;
      }
      return result;
    },
    async assignOfficer() {
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid || this.dateTimeInputState === "error") {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      }
      this.isAssigning = true;

      const requestBody = this.getAssignmentData();

      var resp = await assignExtendedOT(requestBody);
      if (resp.success) {
        this.$router.go(this.$router.currentRoute);
      } else {
        const forcible = get(resp, "payload.forcible", false);
        if (forcible) {
          this.showForceCheckbox = true;
        }
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.isAssigning = false;
    },
  },
};
</script>
