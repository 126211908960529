<template>
  <div class="space-y-4">
    <div class="color-legend flex justify-start space-x-4 mb-4">
      <div class="flex items-center text-xs">
        <div class="h-4 w-4 d-block is-blocked-leave" style="--tw-bg-opacity: .7;"></div><div div class="pl-2">Indicated Leave</div>
      </div>
      <div class="flex items-center text-xs">
        <div class="h-4 w-4 d-block pending-replacement" style="--tw-bg-opacity: .7;"></div> <div class="pl-2">Pending Replacement</div>
      </div>
      <div class="flex items-center text-xs">
        <div class="h-4 w-4 d-block is-sap-conflict" style="--tw-bg-opacity: .7;"></div><div div class="pl-2">SAP Conflict</div>
      </div>
      <div class="flex items-center text-xs">
        <div class="h-4 w-4 d-block ot-volunteer" style="--tw-bg-opacity: .7;"></div><div div class="pl-2">OT Volunteer</div>
      </div>
      <div class="flex items-center text-xs">
        <div class="h-4 w-4 d-block ot-unvailable" style="--tw-bg-opacity: .7;"></div><div div class="pl-2">OT Unavailable</div>
      </div>
      <div class="flex items-center text-xs">
        <asom-icon icon="close" size="sm" />
        <div div class="pl-2">Marked Unavailable</div>
      </div>
      <div class="flex items-center text-xs">
        <asom-icon icon="history" size="sm" />
        <div div class="pl-2">Extended Shift in Another Station</div>
      </div>
    </div>
    <section
      class="roster-grid grid card shadow rounded w-full overflow-scroll"
      :style="{ '--total-columns': fullWeekDates.length + 7 }"
    >
      <!-- Print header rows -->
      <aside
        class="roster-grid_header row-span-3 col-span-2 flex items-center justify-center border-b border-gray-300"
        :style="{ '--left-sticky-columns': 0, 'top': 0, 'z-index': 21 }"
      >
        No
      </aside>
      <aside
        class="roster-grid_header row-span-3 col-span-5 flex items-center border-b border-r border-gray-300"
        :style="{ '--left-sticky-columns': 2, 'top': 0, 'z-index': 21 }"
      >
        Employee Name
      </aside>

      <template v-for="(month, i) in displayedMonths" :key="`month_${i}`">
        <div
          class="roster-grid_month border-b border-r border-l border-gray-300"
          :style="{
            '--top-sticky-columns': 0,
            'grid-column': `span ${month.length} / span ${month.length} `,
          }"
        >
          <span class="sticky" :style="{ '--left-sticky-columns': 2 + 5 }">{{
            month.name
          }}</span>
        </div>
      </template>

      <template v-for="(week, i) in displayedWeeks" :key="`week_${i}`">
        <div
          class="roster-grid_week border-r border-l border-b border-gray-300 space-x-3"
          :style="{
            '--top-sticky-columns': 1,
            'grid-column': `span ${7} / span ${7} `,
          }"
        >
          <span v-for="(word, index) in words(week)" :key="index">
            <span v-if="checkWeek(index)" class="weekStyle">{{ word }} </span>
            <span v-else>{{ word }} </span>
          </span>
          <!-- <span>{{week}}</span> -->
        </div>
      </template>

      <template v-for="(day, i) in displayedDates" :key="`day_${i}`">
        <div
          class="roster-grid_cell roster-grid_day border-r border-l border-b border-gray-300"
          :style="{ '--top-sticky-columns': 2 }"
        >
          <span class="dayName">{{ day.weekdayOnly }}</span
          ><span class="dateNo">{{ day.dayOnly }}</span>
        </div>
      </template>

      <!-- /\ Print header rows -->

      <!-- Print content rows -->
      <template v-for="(role, rIndex) in displayedRoles" :key="`role_${rIndex}`">
        <aside
          class="AsomRosterList--Role roster-grid_row col-span-7 flex items-center  border-r border-gray-300 border-b border-gray-300"
          :class="rIndex % 2 ? 'odd' : 'even'"
          :style="{ '--left-sticky-columns': 0 }"
        >
          <span class="ml-8">{{ role }}</span>
        </aside>
        <div
          class="bg-white border-r border-b border-gray-300"
          :style="{
            'grid-column': `span ${fullWeekDates.length} / span ${fullWeekDates.length} `,
          }"
        >
        </div>
        <template v-for="(officerId, oIndex) in groupByRoleAndOfficer[role]" :key="`role_${rIndex}_emp_${oIndex}_rota`">
          <aside
            class="AsomRosterList--indexNo roster-grid_row col-span-2 flex items-center justify-center bg-gray-50 border-b border-gray-300"
            :class="[oIndex % 2 ? 'odd' : 'even', officerId == userId ? 'roster-grid_highlight' : '']"
            :style="{ '--left-sticky-columns': 0 }"
          >
            {{ oIndex + 1 }}
          </aside>

          <aside
            class="AsomRosterList--Employer roster-grid_row col-span-5 flex items-center  border-r border-gray-300 border-b border-gray-300"
            :class="[oIndex % 2 ? 'odd' : 'even', officerId == userId ? 'roster-grid_highlight-top-bottom' : '']"
            :style="{ '--left-sticky-columns': 2 }"
          >
            {{ officerName(officerId) }}
          </aside>

          <template v-for="(day, i) in displayedDates" :key="`off_${oIndex}_day_${i}`">
            <div
              class="AsomRosterList__Body--InnerGrid--Cell roster-grid_cell roster-grid_row border-r border-l border-b border-gray-300 relative"
              :class="[
                getDwsCellClass(officerId, day.fullDate),
                oIndex % 2 ? 'odd' : 'even',
                isActiveShiftReplaced(officerId, day.fullDate) && 'roster-grid_cell--is-replaced',
                officerId == userId ? 'roster-grid_highlight-top-bottom' : ''
              ]"
              @click="onDwsClick(officerId, day.fullDate)"
            >
              <aside class="absolute w-full h-full top-0 left-0 flex flex-col">
                <div v-for="item in dwsCellStyle(officerId, day.fullDate).filter(x => !x.notColored)"
                  :key="`off_${oIndex}_day_${i}_${item}`"
                  :class="item.css" class="flex-1"
                  style="--tw-bg-opacity: .5;"></div>
              </aside>
              <div class="flex flex-col items-center pt-1">
                <aside class="indicators h-1/3">
                  <asom-tooltip v-if="['RPL','TOR'].includes(get(getRoster(officerId, day.fullDate), 'dws', '').trim().toUpperCase())">
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="hourglass_change" size="xs" /></span>
                    </template>
                    Replaced Shift
                  </asom-tooltip>
                  <asom-tooltip v-else-if="get(getRoster(officerId, day.fullDate), 'isCoveringShift')">
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="hat" size="xs" /></span>
                    </template>
                    Shift in external station
                  </asom-tooltip>
                  <asom-tooltip 
                    v-else-if="coveringAwayStation(officerId, day.fullDate)">
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="running" size="xs" /></span>
                    </template>
                    Work in away station
                  </asom-tooltip>
                  <asom-tooltip
                    v-else-if="coveringSameStation(officerId, day.fullDate)"
                  >
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="home" size="xs" /></span>
                    </template>
                    Dual shift
                  </asom-tooltip>
                  <asom-tooltip v-else-if="get(getRoster(officerId, day.fullDate), 'isMutualExchanged')">
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="exchange" size="xs" /></span>
                    </template>
                    Shift exchanged
                  </asom-tooltip>
                  <asom-tooltip v-else-if="get(getRoster(officerId, day.fullDate), 'otShift')">
                    <template #toggleText>
                      <span class="relative z-10"><asom-icon icon="history" size="xs" /></span>
                    </template>
                    OT Shift
                  </asom-tooltip>
                </aside>

                <asom-tooltip>
                  <template #toggleText>
                    <span class="relative z-10">{{ displayDws(officerId, day.fullDate) }}</span>
                  </template>
                  {{dwsCellStyle(officerId, day.fullDate).map(x => x.label).join(' | ') || '-'}}
                </asom-tooltip>
              </div>
            </div>
          </template>
        </template>
      </template>
      <!-- /\ Print content rows -->
    </section>
  </div>
</template>

<script>
import isEmpty from "lodash.isempty";
import head from "lodash.head";
import last from "lodash.last";
import groupBy from "lodash.groupby";
import orderBy from "lodash.orderby";
import get from "lodash.get";
import moment from 'moment';
import ROLES from '../../../constants/APIEnums/roles'
import filter from "lodash.filter";

export default {
  props: {
    rosters: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: String,
      default: "",
    },
  },
  emits: ["cellClick"],
  computed: {
    roles() {
      return [
        ROLES.SENIOR_STATION_MANAGER.name,
        ROLES.STATION_MANAGER.name,
        ROLES.SENIOR_ASSISTANT_STATION_MANAGER.name,
        ROLES.ASSISTANT_STATION_MANAGER.name,
        ROLES.STATION_MANAGER_CONTROL_ROOM.name
      ]
    },
    displayedRoles() {
      return this.roles.filter((roleName) => !isEmpty(this.groupByRoleAndOfficer[roleName]))
    },
    groupByRoleAndOfficer() {
      let result = {};
      const _orderByPersonNo = orderBy(this.rosters, 'personNo');
      this.roles.forEach((roleName) => {
        const _filterByRole = filter(_orderByPersonNo, { role: roleName });
        const _byOfficerId = groupBy(orderBy(_filterByRole, ["onChangeCoveringStation", "onChangeBaseStation", "baseStation"], ['desc', 'desc', 'desc']), "officerId");
        result[roleName] = Object.keys(_byOfficerId);
      });
      
      return result;
    },
    groupByOfficer() {
      return groupBy(this.rosters, "officerId");
    },
    fullWeekDates() {
      let arr = [];
      this.rosters.forEach(({ planDate }) => {
        if (!arr.includes(planDate)) {
          if (arr.length > 0) {
            let lastMoment = moment(last(arr), "DD/MM/YYYY");
            let parsedDate = lastMoment.add(1, "days").format("DD/MM/YYYY");
            while (parsedDate !== planDate) {
              arr.push(parsedDate);
              parsedDate = lastMoment.add(1, "days").format("DD/MM/YYYY");
            }
          }
          arr.push(planDate);
        }
      });

      if (arr.length > 0) {
        let firstMoment = moment(head(arr), "DD/MM/YYYY");
        while (firstMoment.weekday() !== 1) {
          // Monday
          arr.unshift(firstMoment.add(-1, "days").format("DD/MM/YYYY"));
        }
        let lastMoment = moment(last(arr), "DD/MM/YYYY");
        while (lastMoment.weekday() !== 0) {
          // Sunday
          arr.push(lastMoment.add(1, "days").format("DD/MM/YYYY"));
        }
      }

      return arr.map((d) => moment(d, "DD/MM/YYYY"));
    },
    displayedDates() {
      return this.fullWeekDates.map((d) => ({
        dayOnly: d.format("DD"),
        weekdayOnly: d.format("dd"),
        fullDate: d.format("DD/MM/YYYY"),
      }));
    },
    displayedWeeks() {
      let weeks = [];
      for (let i = 0; i < this.fullWeekDates.length; i += 7) {
        let monday = this.fullWeekDates[i];
        let sunday = this.fullWeekDates[i + 6];
        weeks.push(
          `Week ${Math.floor(i / 7) + 1} (${monday.format(
            "DD/MM"
          )} - ${sunday.format("DD/MM")})`
        );
      }
      return weeks;
    },
    displayedMonths() {
      const groupByMonth = groupBy(this.fullWeekDates, (d) =>
        d.format("YYYYMM")
      );
      return Object.keys(groupByMonth).map((key) => ({
        name: groupByMonth[key][0].format("MMM YYYY"),
        length: groupByMonth[key].length,
      }));
    },
    // officerIds() {
    //   return Object.keys(this.groupByOfficer);
    // },
  },
  methods: {
    get,
    // officerIdsOfRole(role) {
    //   return Object.keys(get(this.groupByRoleAndOfficer, role, {}));
    // },
    officerName(officerId) {
      return get(this.groupByOfficer, `${officerId}.0.officer`);
    },
    getRoster(officerId, planDate) {
      return this.rosters.find(
        (r) => r.officerId === officerId && r.planDate === planDate && !r.isAdditionalShift
      );
    },
    getSameStationCoveringRoster(officerId, planDate) {
      return this.rosters.find(
        (r) => r.officerId === officerId && r.planDate === planDate && r.isAdditionalShift
      );
    },
    getPrevDws(officerId, planDate) {
      const r = this.getRoster(officerId, planDate);
      const dws = get(r, "previousDws", "");
      return dws;
    },
    getDws(officerId, planDate) {
      const r = this.getRoster(officerId, planDate);
      const dws = get(r, "dws", "");
      return dws;
    },
    displayDws(officerId, planDate) {
      const dws = this.getDws(officerId, planDate);
      const prevDws = this.getPrevDws(officerId, planDate);
      switch (dws) {
        case "SPARE":
          return "SPR";
        case 'MTO':
        case 'MUA':
        case 'RPL':
        case 'TOR':
          return prevDws || dws;
        default:
          return dws;
      }
    },
    dwsCellStyle(officerId, planDate) {
      const roster = this.getRoster(officerId, planDate);
      let cssClasses = [];
      if (roster && roster.isBlockedLeave) {
        cssClasses.push({css: "is-blocked-leave", label: 'Indicated Leave'});
      }
      if (roster && roster.pendingReplacement) {
        cssClasses.push({css: "pending-replacement", label: 'Pending Replacement'});
      }
      if (roster && roster.isSapConflict) {
        cssClasses.push({css: "is-sap-conflict", label: 'Sap Conflict'});
      }
      if (roster && roster.otUnavailable) {
        cssClasses.push({css: "ot-unvailable", label: 'OT Unavailable'});
      }
      if (roster && roster.otVolunteer) {
        cssClasses.push({css: "ot-volunteer", label: 'OT Volunteer'});
      }
      if (roster && roster.notApplicable) {
        cssClasses.push({css: "not-applicable", label: 'Active shift in another station', notColored: true});
      }
      return cssClasses;
    },
    getDwsCellClass(officerId, planDate) {
      const dws = this.displayDws(officerId, planDate);
      return dws ? dws.toLowerCase() : "";
    },
    // isUnavailable(officerId, planDate) {
    //   const r = this.getRoster(officerId, planDate);
    //   return get(r, 'unavailability', false);
    // },
    isActiveShiftReplaced(officerId, planDate) {
      const r = this.getRoster(officerId, planDate);
      return get(r, 'notApplicable') ||
        (!isEmpty(get(r, 'previousDws')) && [ 'MTO', 'MUA', 'RPL', 'TOR' ].includes(get(r, 'dws')));
    },
    // isNotApplicable(officerId, planDate) {
    //   const r = this.getRoster(officerId, planDate);
    //   return get(r, 'notApplicable', false);
    // },
    onDwsClick(officerId, planDate) {
      const r = this.getRoster(officerId, planDate);
      if (this.coveringSameStation(officerId, planDate)) {
        const coveringRoster = this.getSameStationCoveringRoster(officerId, planDate);
        r['additionalShift'] = coveringRoster
      }
      this.$emit("cellClick", r);
    },
    words(string) {
      return string.split(/\s+/);
    },
    checkWeek(index) {
      if (index == 2 || index == 3 || index == 4) {
        return true;
      }
    },
    coveringAwayStation(officerId, date) {
      return get(this.getRoster(officerId, date), 'hasCoveringShift') &&
        get(this.getRoster(officerId, date), 'baseStation') !==
        get(this.getRoster(officerId, date), 'coveringStation')
    },
    coveringSameStation(officerId, date) {
      return get(this.getRoster(officerId, date), 'hasCoveringShift') &&
                get(this.getRoster(officerId, date), 'baseStation') ===
          get(this.getRoster(officerId, date), 'coveringStation')
    },
  },
};
</script>

<style lang="scss" scoped>
.weekStyle {
  @apply text-gray-400
}
.roster-grid {
  --total-columns: 0;

  grid-template-columns: repeat(var(--total-columns), theme("height.12"));
  grid-auto-rows: theme("height.12");
  will-change: transform;
  position: relative;
  max-height: 90vh;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
  }

  &_cell {
    width: theme("width.12");
  }

  &_month,
  &_week,
  &_day,
  &_date {
    --top-sticky-columns: 0;

    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;

    top: calc(theme("width.12") * var(--top-sticky-columns));
    z-index: 20;
    @apply tracking-wider;
  }

  &_cell,
  &_month,
  &_week,
  &_day,
  &_date {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_day {
    .dayName {
      font-size: 0.65rem;
      @apply text-gray-400 tracking-wider;
    }

    .dateNo {
      font-size: 0.9rem;
      @apply text-gray-700;
    }

    flex-direction: column;
    @apply bg-gray-100;
  }

  &_month {
    .sticky {
      --left-sticky-columns: 0;

      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;

      left: calc(theme("width.12") * var(--left-sticky-columns));
    }
  }

  &_week {
    white-space: nowrap;
  }

  &_header {
    --left-sticky-columns: 0;

    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;

    left: calc(theme("width.12") * var(--left-sticky-columns));
    @apply tracking-wider;
  }

  &_highlight {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid theme("colors.blue.500");
      border-right: 0;
      border-left-width: 5px;
      top: 0;
      left: 0;
    }
    &-top-bottom {
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid theme("colors.blue.500");
        border-right: 0;
        border-left: 0;
        top: 0;
        left: 0;
      }
    }
  }
}

.roster-grid_header,
.roster-grid_month,
.roster-grid_week {
  @apply bg-gray-50 text-xs uppercase text-gray-600 font-semibold;
}

.AsomRosterList--Role {
  --left-sticky-columns: 0;
  left: calc(theme("width.12") * var(--left-sticky-columns));
  @apply text-sm sticky bg-white text-black font-semibold;
}

.AsomRosterList--Employer,
.AsomRosterList--indexNo {
  left: calc(theme("width.12") * var(--left-sticky-columns));
  @apply text-sm sticky;
  z-index: 11;
}

.roster-grid_day,
.roster-grid_date {
  @apply text-xs  font-semibold uppercase;
}

.AsomRosterList--Employer {
  @apply text-black font-semibold;
}

.roster-grid_header.odd,
.roster-grid_cell.odd,
.AsomRosterList--indexNo.odd,
.AsomRosterList--Employer.odd {
  @apply bg-gray-50;
}

.roster-grid_header.even,
.roster-grid_cell.even,
.AsomRosterList--indexNo.even,
.AsomRosterList--Employer.even {
  @apply bg-white;
}

.AsomRosterList__Body--InnerGrid--Cell {
  @apply font-semibold cursor-pointer text-xs tracking-wider;
}
.AsomRosterList__Body--InnerGrid--Cell.off {
  @apply text-gray-800;
}
.AsomRosterList__Body--InnerGrid--Cell.rest {
  @apply text-gray-500
}
.AsomRosterList__Body--InnerGrid--Cell.spr {
  @apply text-gray-600
}

.is-blocked-leave {
  background-color: theme("colors.indigo.500") !important;
  opacity: var(--tw-bg-opacity);
  &.roster-grid_cell {
    background-color: theme("colors.indigo.100") !important;
  }
}
.pending-replacement {
  background-color: theme("colors.yellow.500") !important;
  opacity: var(--tw-bg-opacity);
  &.roster-grid_cell {
    background-color: theme("colors.yellow.100") !important;
  }
}
.is-sap-conflict {
  background-color: theme("colors.red.500") !important; 
  opacity: var(--tw-bg-opacity);
  &.roster-grid_cell {
    background-color: theme("colors.red.100") !important;
  }
}
.ot-volunteer {
  background-color: theme("colors.green.500") !important;
  opacity: var(--tw-bg-opacity);
}
.ot-unvailable {
  background-color: theme("colors.purple.500") !important;
  opacity: var(--tw-bg-opacity);
}

.left-cross {
  background: linear-gradient(to top right,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0) calc(50% - 2px),
    rgba(0,0,0,1) 50%,
    rgba(0,0,0,0) calc(50% + 2px),
    rgba(0,0,0,0) 100%);
}
.right-cross {
  background: linear-gradient(to top left,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0) calc(50% - 2px),
    rgba(0,0,0,.8) 50%,
    rgba(0,0,0,0) calc(50% + 2px),
    rgba(0,0,0,0) 100%);
}

.roster-grid_cell{
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;

  &--is-replaced {
    &:before, &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: ' ';
      height: 100%;
      width: 100%;
      background-color: transparent;
    }
    &:before {
      @apply left-cross;
    }
    &:after {
      @apply right-cross;
    }
  }
}

.roster-unavailable {
  border: 1px solid rgba(0,0,0,.2);
  @apply left-cross;
}
</style>