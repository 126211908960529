<template>
  <asom-alert variant="warning" v-if="!isBaseStation" title="You are not in your Resident Station">
    <p class="text-sm pt-1">You are viewing station <strong>{{stationName}}</strong> that is not your base station.</p>
  </asom-alert>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    stationId: {
      type: String,
      required: true,
    },
    stationName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      "baseStationId": "auth/userStationId",
    }),
    isBaseStation() {
      return this.stationId === this.baseStationId
    },
  }
}
</script>

<style>

</style>